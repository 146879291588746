<template>
  <div class="site-wrap">
    <Oznam />
    
    <Navbar />

    <HeaderPanel />
    <div class="main-content">
      <router-view />
    </div>
    <Footer />
  </div>
</template>

<script>
export default {
  components: {
    Navbar: () => import("@/layouts/Navbar.vue"),
    HeaderPanel: () => import("@/layouts/HeaderPanel.vue"),
    Footer: () => import("@/layouts/Footer.vue"),
    Oznam: () => import("@/components/Oznam.vue"),
  },
};
</script>